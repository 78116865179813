import React, { useState, useEffect } from "react";
import axios from "axios";
import './TrackShipment.css';

const TrackShipment = ({ orderId }) => {
  const [shipmentData, setShipmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [awsNumber, setAwsNumber] = useState(null);

  useEffect(() => {
    const fetchAwsNumber = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/mapOrder/${orderId}`
        );
        const awsNum = response.data;
        setAwsNumber(awsNum);
        console.log("awsnumber",awsNumber);
        if (!awsNum) {
          setError("The order is in process.");
        }
      } catch (err) {
        console.error("Error fetching AWS number:", err);
        setError("The order is in process.");
      } finally {
        setLoading(false);
      }
    };

    if (orderId) {
      fetchAwsNumber();
    }
  }, [orderId]);

  // useEffect(() => {
  //   const fetchShipmentData = async () => {
  //     if (!awsNumber) return;

  //     setLoading(true);
  //     setError(null);

  //     try {
  //       const response = await axios.get(
  //         // `https://thingproxy.freeboard.io/fetch/https://track.delhivery.com/api/v1/packages/json/?waybill=${awsNumber}`,
  //          `https://track.delhivery.com/api/v1/packages/json/?waybill=${awsNumber}`,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: "Token 497be93ecebccb78e0eeeb52be3876a0cdf54459",
  //           }
  //         }
  //       );

  //       console.log("response",response);
  //       // console.log("day",expectedDeliveryDate)
  //       const shipmentData = response.data.ShipmentData.map(item => {
  //         const shipment = item.Shipment;

  //         return {
  //           pickUpDate: shipment.PickUpDate,
  //           destination: shipment.Destination,
  //           status: shipment.Status.Status,
  //           statusLocation: shipment.Status.StatusLocation,
  //           statusDateTime: shipment.Status.StatusDateTime,
  //           scannedLocation: shipment.Scans ? shipment.Scans.map(scan => scan.ScanDetail.ScannedLocation) : [],
  //           instructions: shipment.Scans ? shipment.Scans.map(scan => scan.ScanDetail.Instructions) : [],
  //           invoiceAmount: shipment.InvoiceAmount,
  //           expectedDeliveryDate: shipment.ExpectedDeliveryDate,
  //           senderName: shipment.SenderName,
  //           awb: shipment.AWB,
  //           quantity: shipment.Quantity,
  //           consignee: {
  //             name: shipment.Consignee.Name,
  //             city: shipment.Consignee.City,
  //             state: shipment.Consignee.State,
  //             country: shipment.Consignee.Country,
  //             pinCode: shipment.Consignee.PinCode,
  //             telephone1: shipment.Consignee.Telephone1,
  //             telephone2: shipment.Consignee.Telephone2,
  //           }
  //         };
  //       });

  //       setShipmentData(shipmentData);
  //     } catch (error) {
  //       console.error('Error fetching shipment data:', error);
  //       setError("The order has not been confirmed yet. We will inform you once confirmed");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchShipmentData();
  // }, [awsNumber]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  // if (!shipmentData || shipmentData.length === 0) return <div>No shipment data found.</div>;

  // const shipment = shipmentData[0]; // Assuming we only need the first shipment
  // const { Scans = [], status, statusLocation, statusDateTime, expectedDeliveryDate } = shipment; // Default Scans to an empty array

  // // Define the stages for the progress tracker
  // const stages = [
  //   { label: "Order Received", statusKey: "Manifested" },
  //   { label: "Ready To Ship", statusKey: "Ready To Ship" },
  //   { label: "Scheduled for Pickup", statusKey: "Scheduled for Pickup" },
  //   { label: "In Transit", statusKey: "In Transit" },
  //   { label: "Out for Delivery", statusKey: "Out for Delivery" },
  //   { label: "Delivered", statusKey: "Delivered" }
  // ];

  // // Determine the current stage index based on the actual status of the shipment
  // let currentStageIndex = stages.findIndex(
  //   (stage) => stage.statusKey === status
  // );

  // // Handle case when no matching status is found (default to the first stage)
  // if (currentStageIndex === -1) currentStageIndex = 0;

  // // Map the scans to corresponding stages and include respective dates dynamically
  // const stageDates = stages.map((stage) => {
  //   const scan = Scans.find((s) => s.Status === stage.statusKey);
  //   return scan ? scan.StatusDateTime : null; // use the date from scans if available
  // });

  // // Format date and time for display
  // const formatDate = (dateString) =>
  //   new Date(dateString).toLocaleString("en-US", {
  //     year: "numeric",
  //     month: "short",
  //     day: "numeric",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //   });
    

  return (
    <div className="tracking-page">
      <h1 className="title">Track Your Shipment</h1>
      <div className="instructions">
        <p>Use this AWB Number to track your package:</p>
        <h2>{awsNumber}</h2>
        <p>Steps to track your shipment:</p>
        <ol>
          <li>
            Open the link:{" "}
            <a href="https://www.delhivery.com/tracking" target="_blank" rel="noopener noreferrer">
            https://www.delhivery.com/tracking
            </a>
          </li>
          <li>Select the <strong>AWB</strong> option from the four provided:</li>
          <ul>
            <li>Mobile Number</li>
            <li>AWB</li>
            <li>Order ID</li>
            <li>LRN</li>
          </ul>
          <li>Enter the AWB Number: <strong>{awsNumber}</strong></li>
          <li>Click the <strong>Track Order</strong> button.</li>
        </ol>
      </div>
    </div>
  );
};

export default TrackShipment;
